import axios from "axios";
import { config } from '../../config';

const ENDPOINT_URL = "auth/";
const BASE_URL = config.BASE_URL;

export function login(email, password) {
	return axios.post(`${BASE_URL}${ENDPOINT_URL}login`, { email, password });
}

export function forgotPassword(email) {
	return axios.post(`${BASE_URL}${ENDPOINT_URL}lost-password`, { email })
}

export function changePassword(token, password) {
	return axios.post(`${BASE_URL}${ENDPOINT_URL}change-password`, { token, password })
}

export function getUserByToken() {
	return axios.get(`${BASE_URL}${ENDPOINT_URL}`);
}

export function refreshToken(refreshToken, userid) {
	return axios.post(`${BASE_URL}${ENDPOINT_URL}token`, { refreshToken, id: userid });
}
