import React, { useEffect, useState } from "react";

export const DebugPage = () => {
    const [zoomLevel, setZoomLevel] = useState(window.devicePixelRatio * 100);
    const [resolution, setResolution] = useState({
        width: window.screen.width,
        height: window.screen.height,
    });
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        // Function to update the zoom level
        const updateZoomLevel = () => {
            setZoomLevel(window.devicePixelRatio * 100);
        };

        // Function to update the screen resolution
        const updateResolution = () => {
            setResolution({
                width: window.screen.width,
                height: window.screen.height,
            });
        };

        const updateWindowSize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        // Add event listeners
        window.addEventListener("resize", () => {
            updateZoomLevel();
            updateResolution();
            updateWindowSize();
        });

        // Clean up event listeners on unmount
        return () => {
            window.removeEventListener("resize", updateZoomLevel);
            window.removeEventListener("resize", updateResolution);
            window.removeEventListener("resize", updateWindowSize);
        };
    }, []);

    return (
        <div>
            <h3>Zoom Level: {zoomLevel}%</h3>
            <h3>Screen Resolution: {resolution.width}x{resolution.height}</h3>
            <h3>Window Size: {windowSize.width}x{windowSize.height}</h3>
        </div>
    );
};
