import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../api/auth";

function Login(props) {
	const [loading, setLoading] = useState(false);
	const [loadingButtonStyle, setLoadingButtonStyle] = useState({
		paddingRight: "2.5rem"
	});

	const enableLoading = () => {
		setLoading(true);
		setLoadingButtonStyle({ paddingRight: "3.5rem" });
	};

	const disableLoading = () => {
		setLoading(false);
		setLoadingButtonStyle({ paddingRight: "2.5rem" });
	};

	return (
		<>
			{/* <div className="kt-login__head">
        
      </div> */}

			<div className="kt-login__body">
				<div className="kt-login__form">
					<div className="kt-login__title">
						<h3>
							{/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
							{/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
							Είσοδος στον λογαριασμό σας
						</h3>
					</div>

	  				{/* TODO FIXME remove initial values */}
					<Formik
						initialValues={{
							email: '',
							password: ''
						}}
						validate={values => {
							const errors = {};

							if (!values.email) {
								// https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
								errors.email = 'Το email είναι απαραίτητο';
							} else if (
								!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
							) {
								errors.email = 'Μη έγκυρο email. Διορθώστε το email που εισάγατε.'
							}

							if (!values.password) {
								// errors.password = intl.formatMessage({
								//   id: "AUTH.VALIDATION.REQUIRED_FIELD"
								// });
								errors.password = 'Ο κωδικός είναι απαραίτητος'
							}

							return errors;
						}}
						onSubmit={(values, { setStatus, setSubmitting }) => {
							enableLoading();
							login(values.email, values.password)
								.then(({ data }) => {
									props.loginAction(data.token, data.refreshToken);
									disableLoading();
									setSubmitting(false);
								})
								.catch((err) => {
									disableLoading();
									setSubmitting(false);

									if (err.response) {
										switch (err.response.status) {
											case 401: setStatus('Τα στοιχεία πρόσβασης δεν είναι σωστά.'); break;
											case 420: setStatus('Δεν έχει γίνει επιβεβαίωση του λογαριασμού σας.'); break;
											default: setStatus('Πρόβλημα κατά την είσοδο. Επικοινωνήστε με την υποστήριξη'); break;
										}
									} else {
										setStatus('Πρόβλημα κατά την είσοδο. Επικοινωνήστε με την υποστήριξη');
									}									
								});
						}}
					>
						{({
							values,
							status,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting
						}) => (
								<form
									noValidate={true}
									autoComplete="off"
									className="kt-form"
									onSubmit={handleSubmit}
								>
									{status && (
										<div role="alert" className="alert alert-danger">
											<div className="alert-text">{status}</div>
										</div>
									)}

									<div className="form-group">
										<TextField
											type="email"
											label="Email"
											margin="normal"
											className="kt-width-full"
											name="email"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.email}
											helperText={touched.email && errors.email}
											error={Boolean(touched.email && errors.email)}
										/>
									</div>

									<div className="form-group">
										<TextField
											type="password"
											margin="normal"
											label="Κωδικός"
											className="kt-width-full"
											name="password"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.password}
											helperText={touched.password && errors.password}
											error={Boolean(touched.password && errors.password)}
										/>
									</div>

									<div className="kt-login__actions">
										<Link
											to="/auth/forgot-password"
											className="kt-link kt-login__link-forgot"
										>
											Ξεχάσατε τον κωδικό σας;
                    {/* <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" /> */}
										</Link>

										<button
											id="kt_login_signin_submit"
											type="submit"
											disabled={isSubmitting}
											className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
												{
													"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
												}
											)}`}
											style={loadingButtonStyle}
										>
											Είσοδος
                  						</button>
									</div>
									{/* <div style={{ textAlign: 'center' }}>
										<span className="kt-login__signup-label">Δεν έχετε λογαριασμό;</span>
										&nbsp;&nbsp;
										<Link to="/auth/registration" className="kt-link kt-login__signup-link">Εγγραφείτε!</Link>
									</div> */}
								</form>
							)}
					</Formik>
				</div>
			</div>
		</>
	);
}

export default injectIntl(
	connect(
		null,
		auth.actions
	)(Login)
);
