import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "../../api/auth";
import * as routerHelpers from "../../router/RouterHelpers";

export const actionTypes = {
	Login: "[Login] Action",
	RefreshToken: "[RefreshToken] Action",
	Logout: "[Logout] Action",
	Register: "[Register] Action",
	UserRequested: "[Request User] Action",
	UserLoaded: "[Load User] Auth API"
};

const initialAuthState = {
	user: undefined,
	token: undefined,
	refreshToken: undefined,
};

export const reducer = persistReducer(
	{ storage, key: "auth", whitelist: ["user", "token", "refreshToken"] },
	(state = initialAuthState, action) => {
		switch (action.type) {
			case actionTypes.Login: {
				const { token, refreshToken } = action.payload;

				return { token, refreshToken, user: undefined };
			}

			case actionTypes.RefreshToken: {
				const { refreshToken, token } = action.payload;
				return { ...state, refreshToken, token };
			}

			case actionTypes.Register: {
				const { token, refreshToken } = action.payload;

				return { token, refreshToken, user: undefined };
			}

			case actionTypes.Logout: {
				routerHelpers.forgotLastLocation();
				return initialAuthState;
			}

			case actionTypes.UserLoaded: {
				const { user } = action.payload;

				return { ...state, user };
			}

			default:
				return state;
		}
	}
);

export const actions = {
	loginAction: (token, refreshToken) => ({ type: actionTypes.Login, payload: { token, refreshToken } }),
	refreshTokenAction: (token, refreshToken) => ({ type: actionTypes.RefreshToken, payload: { token, refreshToken } }),
	registerAction: (token, refreshToken) => ({ type: actionTypes.Register, payload: { token, refreshToken } }),
	logoutAction: () => ({ type: actionTypes.Logout }),
	requestUserAction: user => ({ type: actionTypes.UserRequested, payload: { user } }),
	fulfillUserAction: user => ({ type: actionTypes.UserLoaded, payload: { user } })
};

export function* saga() {
	yield takeLatest(actionTypes.Login, function* loginSaga() {
		yield put(actions.requestUserAction());
	});

	yield takeLatest(actionTypes.Register, function* registerSaga() {
		yield put(actions.requestUserAction());
	});

	yield takeLatest(actionTypes.UserRequested, function* userRequested() {
		const { data: user } = yield getUserByToken();

		yield put(actions.fulfillUserAction(user));
	});
}
