export default {
	header: {
		self: {},
		items: [
			{
				title: "Ημερήσιες Καταγραφές",
				root: true,
				alignment: "left",
				page: "daily-record",
				// translate: "MENU.INCOME",
				toggle: "click",
				submenu: [
					{
						title: "Λίστα Καταγραφών",
						page: "daily-record/all",
						icon: "flaticon-list-1",
					},
					{
						title: "Νέα Καταγραφή",
						page: "daily-record/add",
						icon: "flaticon-file-1",
					}
				]
			},
			{
				title: "Έσοδα",
				root: true,
				alignment: "left",
				page: "income",
				// translate: "MENU.INCOME",
				toggle: "click",
				submenu: [
					{
						title: "Λίστα Εσόδων",
						page: "income/all",
						icon: "flaticon-list-1",
					},
					{
						title: "Νέο Έσοδο",
						page: "income/add",
						icon: "flaticon-file-1",
					}
				]
			},
			{
				title: "Έξοδα",
				root: true,
				alignment: "left",
				page: "expense",
				// translate: "MENU.INCOME",
				toggle: "click",
				submenu: [
					{
						title: "Λίστα Εξόδων",
						page: "expense/all",
						icon: "flaticon-list-1",
					},
					{
						title: "Νέο Έξοδο",
						page: "expense/add",
						icon: "flaticon-file-1",
					}
				]
			},
			{
				title: "Εισπράξεις",
				root: true,
				alignment: "left",
				page: "collection",
				// translate: "MENU.INCOME",
				toggle: "click",
				submenu: [
					{
						title: "Λίστα Εισπράξεων",
						page: "collection/all",
						icon: "flaticon-list-1",
					},
					{
						title: "Νέα Είσπραξη",
						page: "collection/add",
						icon: "flaticon-file-1",
					}
				]
			},
			{
				title: "Εκτροφές",
				root: true,
				alignment: "left",
				page: "lot",
				// translate: "MENU.INCOME",
				toggle: "click",
				submenu: [
					{
						title: "Ονομασίες Εκτροφών",
						page: "lot/lot-names/all",
						icon: "flaticon-list-1",
					},
					{
						title: "Λίστα Εκτροφών",
						page: "lot/all",
						icon: "flaticon-list-1",
					},
					{
						title: "Νέα Εκτροφή",
						page: "lot/add",
						icon: "flaticon-file-1",
					}
				]
			},
			{
				title: "Σφαγές",
				root: true,
				alignment: "left",
				page: "slaughter",
				// translate: "MENU.INCOME",
				toggle: "click",
				submenu: [
					{
						title: "Λίστα Σφαγών",
						page: "slaughter/all",
						icon: "flaticon-list-1",
					},
					{
						title: "Νέα Σφαγή",
						page: "slaughter/add",
						icon: "flaticon-file-1",
					}
				]
			},
			{
				title: "Αναφορές",
				root: true,
				alignment: "left",
				page: "reports",
				// translate: "MENU.INCOME",
				toggle: "click",
				submenu: [
					{
						title: "Λίστα Θεραπειών",
						page: "reports/cures",
						icon: "flaticon-list-1",
					},
					{
						title: "Λιστα Εργατικών",
						page: "reports/labours",
						icon: "flaticon-list-1",
					},
					{
						title: "Λιστα Σημειώσεων",
						page: "reports/notes",
						icon: "flaticon-list-1",
					}
				]
			},
			{
				title: "Παραμετροποίηση",
				root: true,
				alignment: "left",
				page: "settings",
				// translate: "MENU.INCOME",
				toggle: "click",
				submenu: [
					{
						title: "Τύποι Εσόδων",
						page: "settings/income-types/all",
						// icon: "flaticon-file-1",
					},
					{
						title: "Τύποι Εξόδων",
						page: "settings/expense-types/all",
						// icon: "flaticon-file-1",
					},
					{
						title: "Τύποι Εισπράξεων",
						page: "settings/collection-types/all",
						// icon: "flaticon-file-1",
					},
					{
						title: "Θάλαμοι",
						page: "settings/chambers/all",
						// icon: "flaticon-file-1",
					},
					{
						title: "Ράτσες",
						page: "settings/races/all",
						// icon: "flaticon-file-1",
					}
				]
			}
		]
	}
};
