import axios from "axios";
import { config } from '../../config';

const BASE_URL = config.BASE_URL;
const ENDPOINT_URL = "preferences/";

export function getPreference(name) {
	return axios.get(`${BASE_URL}${ENDPOINT_URL}${name}`);
}

export function updatePreference(name, value) {
	return axios.post(`${BASE_URL}${ENDPOINT_URL}`, { name, value });
}